import React, { ReactElement } from 'react';
import RootLayout from 'layouts/RootLayout';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useWindowSize from '../utils/hooks/useWindowSize';

export default function Page404() {
  const router = useRouter();
  const { width } = useWindowSize();
  return (
    <div className="page-404">
      <div className="container">
        <div className="page-404__texts">
          <div className="page-404__texts-text">
            The page doesn't exist, {width <= 475 && <br />} and this link seems not valid. <br /> Start browsing from
            the home page, {width <= 475 && <br />} or <Link href="/company/contact-us">Contact Us</Link>
          </div>
          <button onClick={() => router.push('/')} className="page-404__texts-btn">
            Go to Homepage
          </button>
        </div>

        <div className="page-404__images">
          <div className="page-404__images-404">
            <img
              srcSet="/images/pages/page404/tablet-number-left.png, /images/pages/page404/tablet-number-left.png 769w, /images/pages/page404/mobile-number-left.png 475w"
              src="/images/pages/page404/number.png"
              alt="4"
            />
          </div>
          <div className="page-404__images-center">
            <img src="/images/pages/page404/center-number.png" alt="0" />
          </div>
          <div className="page-404__images-404">
            <img
              srcSet="/images/pages/page404/tablet-number-right.png, /images/pages/page404/tablet-number-right.png 769w,  /images/pages/page404/mobile-number-right.png 475w"
              src="/images/pages/page404/number.png"
              alt="4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Page404.getLayout = function getLayout(page: ReactElement) {
  return <RootLayout>{page}</RootLayout>;
};
